@import "node_modules/bootstrap/scss/functions";
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap');

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1540px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");


// $gray-100: $white !default;
$gray-200: #FBFCFE !default;
$gray-300: #CACAD6 !default;
$gray-400: #A8A9BB !default;
$gray-500: #8688A0 !default;
$gray-600: #6c757d !default;
$gray-700: #010619 !default;
$gray-800: #010619 !default;
$gray-900: #0C202F !default;

// $blue:    #043565 !default;
$blue:    #043565 !default;
$green:    #d9edb5 !default;
// $link-color: #446585 !default;

//$primary:       $blue !default;
$light:         $gray-200 !default;
$dark:          $gray-700 !default;


$body-bg:                   $gray-200 !default;
$body-color:                $gray-900 !default;

// $link-hover-color:                        lighten($link-color, 30%) !default;

$font-family-sans-serif:      "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-family-secondary: 'Caveat', cursive;

$font-weight-bold:            600 !default;
$font-weight-bolder: 600;
$headings-font-weight:        400 !default;
$headings-line-height:        1.5 !default;
$line-height-base:            2 !default;
$enable-responsive-font-sizes:                true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6:($spacer*5),
    7:($spacer*7),
    8:($spacer*9)
  ),
  $spacers
);

$link-color:                              $blue !default;
$link-decoration:                         none !default;
$link-hover-decoration:                   none !default;


