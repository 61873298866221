@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap");
/* line 1, node_modules/bootstrap/scss/_root.scss */
:root {
  --blue: #043565;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #d9edb5;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #010619;
  --primary: #043565;
  --secondary: #6c757d;
  --success: #d9edb5;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #FBFCFE;
  --dark: #010619;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

/* line 19, node_modules/bootstrap/scss/_reboot.scss */
*,
*::before,
*::after {
  box-sizing: border-box; }

/* line 25, node_modules/bootstrap/scss/_reboot.scss */
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* line 35, node_modules/bootstrap/scss/_reboot.scss */
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

/* line 46, node_modules/bootstrap/scss/_reboot.scss */
body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 2;
  color: #0C202F;
  text-align: left;
  background-color: #FBFCFE; }

/* line 66, node_modules/bootstrap/scss/_reboot.scss */
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

/* line 76, node_modules/bootstrap/scss/_reboot.scss */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

/* line 92, node_modules/bootstrap/scss/_reboot.scss */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

/* line 101, node_modules/bootstrap/scss/_reboot.scss */
p {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 114, node_modules/bootstrap/scss/_reboot.scss */
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

/* line 123, node_modules/bootstrap/scss/_reboot.scss */
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

/* line 129, node_modules/bootstrap/scss/_reboot.scss */
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 136, node_modules/bootstrap/scss/_reboot.scss */
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

/* line 143, node_modules/bootstrap/scss/_reboot.scss */
dt {
  font-weight: 600; }

/* line 147, node_modules/bootstrap/scss/_reboot.scss */
dd {
  margin-bottom: .5rem;
  margin-left: 0; }

/* line 152, node_modules/bootstrap/scss/_reboot.scss */
blockquote {
  margin: 0 0 1rem; }

/* line 156, node_modules/bootstrap/scss/_reboot.scss */
b,
strong {
  font-weight: 600; }

/* line 161, node_modules/bootstrap/scss/_reboot.scss */
small {
  font-size: 80%; }

/* line 170, node_modules/bootstrap/scss/_reboot.scss */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

/* line 178, node_modules/bootstrap/scss/_reboot.scss */
sub {
  bottom: -.25em; }

/* line 179, node_modules/bootstrap/scss/_reboot.scss */
sup {
  top: -.5em; }

/* line 186, node_modules/bootstrap/scss/_reboot.scss */
a {
  color: #043565;
  text-decoration: none;
  background-color: transparent; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  a:hover {
    color: #010e1b;
    text-decoration: none; }

/* line 202, node_modules/bootstrap/scss/_reboot.scss */
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

/* line 217, node_modules/bootstrap/scss/_reboot.scss */
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

/* line 225, node_modules/bootstrap/scss/_reboot.scss */
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

/* line 242, node_modules/bootstrap/scss/_reboot.scss */
figure {
  margin: 0 0 1rem; }

/* line 252, node_modules/bootstrap/scss/_reboot.scss */
img {
  vertical-align: middle;
  border-style: none; }

/* line 257, node_modules/bootstrap/scss/_reboot.scss */
svg {
  overflow: hidden;
  vertical-align: middle; }

/* line 269, node_modules/bootstrap/scss/_reboot.scss */
table {
  border-collapse: collapse; }

/* line 273, node_modules/bootstrap/scss/_reboot.scss */
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

/* line 285, node_modules/bootstrap/scss/_reboot.scss */
th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

/* line 296, node_modules/bootstrap/scss/_reboot.scss */
label {
  display: inline-block;
  margin-bottom: 0.5rem; }

/* line 305, node_modules/bootstrap/scss/_reboot.scss */
button {
  border-radius: 0; }

/* line 315, node_modules/bootstrap/scss/_reboot.scss */
button:focus:not(:focus-visible) {
  outline: 0; }

/* line 319, node_modules/bootstrap/scss/_reboot.scss */
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/* line 330, node_modules/bootstrap/scss/_reboot.scss */
button,
input {
  overflow: visible; }

/* line 335, node_modules/bootstrap/scss/_reboot.scss */
button,
select {
  text-transform: none; }

/* line 343, node_modules/bootstrap/scss/_reboot.scss */
[role="button"] {
  cursor: pointer; }

/* line 350, node_modules/bootstrap/scss/_reboot.scss */
select {
  word-wrap: normal; }

/* line 358, node_modules/bootstrap/scss/_reboot.scss */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/* line 371, node_modules/bootstrap/scss/_reboot.scss */
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

/* line 378, node_modules/bootstrap/scss/_reboot.scss */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

/* line 386, node_modules/bootstrap/scss/_reboot.scss */
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

/* line 393, node_modules/bootstrap/scss/_reboot.scss */
textarea {
  overflow: auto;
  resize: vertical; }

/* line 399, node_modules/bootstrap/scss/_reboot.scss */
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

/* line 414, node_modules/bootstrap/scss/_reboot.scss */
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    /* line 414, node_modules/bootstrap/scss/_reboot.scss */
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

/* line 426, node_modules/bootstrap/scss/_reboot.scss */
progress {
  vertical-align: baseline; }

/* line 431, node_modules/bootstrap/scss/_reboot.scss */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/* line 436, node_modules/bootstrap/scss/_reboot.scss */
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

/* line 449, node_modules/bootstrap/scss/_reboot.scss */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* line 458, node_modules/bootstrap/scss/_reboot.scss */
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

/* line 467, node_modules/bootstrap/scss/_reboot.scss */
output {
  display: inline-block; }

/* line 471, node_modules/bootstrap/scss/_reboot.scss */
summary {
  display: list-item;
  cursor: pointer; }

/* line 476, node_modules/bootstrap/scss/_reboot.scss */
template {
  display: none; }

/* line 482, node_modules/bootstrap/scss/_reboot.scss */
[hidden] {
  display: none !important; }

/* line 7, node_modules/bootstrap/scss/_type.scss */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.5; }

/* line 16, node_modules/bootstrap/scss/_type.scss */
h1, .h1 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    /* line 16, node_modules/bootstrap/scss/_type.scss */
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

/* line 17, node_modules/bootstrap/scss/_type.scss */
h2, .h2 {
  font-size: 2rem; }
  @media (max-width: 1200px) {
    /* line 17, node_modules/bootstrap/scss/_type.scss */
    h2, .h2 {
      font-size: calc(1.325rem + 0.9vw) ; } }

/* line 18, node_modules/bootstrap/scss/_type.scss */
h3, .h3 {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    /* line 18, node_modules/bootstrap/scss/_type.scss */
    h3, .h3 {
      font-size: calc(1.3rem + 0.6vw) ; } }

/* line 19, node_modules/bootstrap/scss/_type.scss */
h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    /* line 19, node_modules/bootstrap/scss/_type.scss */
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

/* line 20, node_modules/bootstrap/scss/_type.scss */
h5, .h5 {
  font-size: 1.25rem; }

/* line 21, node_modules/bootstrap/scss/_type.scss */
h6, .h6 {
  font-size: 1rem; }

/* line 23, node_modules/bootstrap/scss/_type.scss */
.lead {
  font-size: 1.25rem;
  font-weight: 300; }

/* line 29, node_modules/bootstrap/scss/_type.scss */
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.5; }
  @media (max-width: 1200px) {
    /* line 29, node_modules/bootstrap/scss/_type.scss */
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

/* line 34, node_modules/bootstrap/scss/_type.scss */
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.5; }
  @media (max-width: 1200px) {
    /* line 34, node_modules/bootstrap/scss/_type.scss */
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

/* line 39, node_modules/bootstrap/scss/_type.scss */
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.5; }
  @media (max-width: 1200px) {
    /* line 39, node_modules/bootstrap/scss/_type.scss */
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

/* line 44, node_modules/bootstrap/scss/_type.scss */
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5; }
  @media (max-width: 1200px) {
    /* line 44, node_modules/bootstrap/scss/_type.scss */
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

/* line 55, node_modules/bootstrap/scss/_type.scss */
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* line 67, node_modules/bootstrap/scss/_type.scss */
small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

/* line 73, node_modules/bootstrap/scss/_type.scss */
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

/* line 84, node_modules/bootstrap/scss/_type.scss */
.list-unstyled {
  padding-left: 0;
  list-style: none; }

/* line 89, node_modules/bootstrap/scss/_type.scss */
.list-inline {
  padding-left: 0;
  list-style: none; }

/* line 92, node_modules/bootstrap/scss/_type.scss */
.list-inline-item {
  display: inline-block; }
  /* line 95, node_modules/bootstrap/scss/_type.scss */
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

/* line 106, node_modules/bootstrap/scss/_type.scss */
.initialism {
  font-size: 90%;
  text-transform: uppercase; }

/* line 112, node_modules/bootstrap/scss/_type.scss */
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

/* line 117, node_modules/bootstrap/scss/_type.scss */
.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  /* line 122, node_modules/bootstrap/scss/_type.scss */
  .blockquote-footer::before {
    content: "\2014\00A0"; }

/* line 8, node_modules/bootstrap/scss/_images.scss */
.img-fluid {
  max-width: 100%;
  height: auto; }

/* line 14, node_modules/bootstrap/scss/_images.scss */
.img-thumbnail {
  padding: 0.25rem;
  background-color: #FBFCFE;
  border: 1px solid #CACAD6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

/* line 29, node_modules/bootstrap/scss/_images.scss */
.figure {
  display: inline-block; }

/* line 34, node_modules/bootstrap/scss/_images.scss */
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

/* line 39, node_modules/bootstrap/scss/_images.scss */
.figure-caption {
  font-size: 90%;
  color: #6c757d; }

/* line 7, node_modules/bootstrap/scss/_grid.scss */
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  /* line 20, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  /* line 20, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  /* line 20, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  /* line 20, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1600px) {
  /* line 20, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1540px; } }

/* line 49, node_modules/bootstrap/scss/_grid.scss */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

/* line 55, node_modules/bootstrap/scss/_grid.scss */
.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  /* line 59, node_modules/bootstrap/scss/_grid.scss */
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

/* line 8, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

/* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

/* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

/* line 60, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-first {
  order: -1; }

/* line 62, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-last {
  order: 13; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-0 {
  order: 0; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-1 {
  order: 1; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-2 {
  order: 2; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-3 {
  order: 3; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-4 {
  order: 4; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-5 {
  order: 5; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-6 {
  order: 6; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-7 {
  order: 7; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-8 {
  order: 8; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-9 {
  order: 9; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-10 {
  order: 10; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-11 {
  order: 11; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-12 {
  order: 12; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-1 {
  margin-left: 8.33333%; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-2 {
  margin-left: 16.66667%; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-3 {
  margin-left: 25%; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-4 {
  margin-left: 33.33333%; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-5 {
  margin-left: 41.66667%; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-6 {
  margin-left: 50%; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-7 {
  margin-left: 58.33333%; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-8 {
  margin-left: 66.66667%; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-9 {
  margin-left: 75%; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-10 {
  margin-left: 83.33333%; }

/* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 60, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-first {
    order: -1; }
  /* line 62, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-last {
    order: 13; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-0 {
    order: 0; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-1 {
    order: 1; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-2 {
    order: 2; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-3 {
    order: 3; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-4 {
    order: 4; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-5 {
    order: 5; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-6 {
    order: 6; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-7 {
    order: 7; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-8 {
    order: 8; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-9 {
    order: 9; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-10 {
    order: 10; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-11 {
    order: 11; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-12 {
    order: 12; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-0 {
    margin-left: 0; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-1 {
    margin-left: 8.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-2 {
    margin-left: 16.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-3 {
    margin-left: 25%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-4 {
    margin-left: 33.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-5 {
    margin-left: 41.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-6 {
    margin-left: 50%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-7 {
    margin-left: 58.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-8 {
    margin-left: 66.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-9 {
    margin-left: 75%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-10 {
    margin-left: 83.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 60, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-first {
    order: -1; }
  /* line 62, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-last {
    order: 13; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-0 {
    order: 0; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-1 {
    order: 1; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-2 {
    order: 2; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-3 {
    order: 3; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-4 {
    order: 4; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-5 {
    order: 5; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-6 {
    order: 6; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-7 {
    order: 7; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-8 {
    order: 8; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-9 {
    order: 9; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-10 {
    order: 10; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-11 {
    order: 11; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-12 {
    order: 12; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-0 {
    margin-left: 0; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-1 {
    margin-left: 8.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-2 {
    margin-left: 16.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-3 {
    margin-left: 25%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-4 {
    margin-left: 33.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-5 {
    margin-left: 41.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-6 {
    margin-left: 50%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-7 {
    margin-left: 58.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-8 {
    margin-left: 66.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-9 {
    margin-left: 75%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-10 {
    margin-left: 83.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 60, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-first {
    order: -1; }
  /* line 62, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-last {
    order: 13; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-0 {
    order: 0; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-1 {
    order: 1; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-2 {
    order: 2; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-3 {
    order: 3; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-4 {
    order: 4; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-5 {
    order: 5; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-6 {
    order: 6; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-7 {
    order: 7; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-8 {
    order: 8; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-9 {
    order: 9; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-10 {
    order: 10; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-11 {
    order: 11; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-12 {
    order: 12; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-0 {
    margin-left: 0; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-1 {
    margin-left: 8.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-2 {
    margin-left: 16.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-3 {
    margin-left: 25%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-4 {
    margin-left: 33.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-5 {
    margin-left: 41.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-6 {
    margin-left: 50%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-7 {
    margin-left: 58.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-8 {
    margin-left: 66.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-9 {
    margin-left: 75%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-10 {
    margin-left: 83.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 60, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-first {
    order: -1; }
  /* line 62, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-last {
    order: 13; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-0 {
    order: 0; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-1 {
    order: 1; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-2 {
    order: 2; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-3 {
    order: 3; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-4 {
    order: 4; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-5 {
    order: 5; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-6 {
    order: 6; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-7 {
    order: 7; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-8 {
    order: 8; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-9 {
    order: 9; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-10 {
    order: 10; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-11 {
    order: 11; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-12 {
    order: 12; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-0 {
    margin-left: 0; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-1 {
    margin-left: 8.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-2 {
    margin-left: 16.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-3 {
    margin-left: 25%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-4 {
    margin-left: 33.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-5 {
    margin-left: 41.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-6 {
    margin-left: 50%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-7 {
    margin-left: 58.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-8 {
    margin-left: 66.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-9 {
    margin-left: 75%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-10 {
    margin-left: 83.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 54, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 60, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-first {
    order: -1; }
  /* line 62, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-last {
    order: 13; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-0 {
    order: 0; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-1 {
    order: 1; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-2 {
    order: 2; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-3 {
    order: 3; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-4 {
    order: 4; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-5 {
    order: 5; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-6 {
    order: 6; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-7 {
    order: 7; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-8 {
    order: 8; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-9 {
    order: 9; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-10 {
    order: 10; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-11 {
    order: 11; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xxl-12 {
    order: 12; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-0 {
    margin-left: 0; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-3 {
    margin-left: 25%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-6 {
    margin-left: 50%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-9 {
    margin-left: 75%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  /* line 72, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

/* line 7, node_modules/bootstrap/scss/_buttons.scss */
.btn {
  display: inline-block;
  font-weight: 400;
  color: #0C202F;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 2;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 7, node_modules/bootstrap/scss/_buttons.scss */
    .btn {
      transition: none; } }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn:hover {
    color: #0C202F;
    text-decoration: none; }
  /* line 27, node_modules/bootstrap/scss/_buttons.scss */
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(4, 53, 101, 0.25); }
  /* line 34, node_modules/bootstrap/scss/_buttons.scss */
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  /* line 40, node_modules/bootstrap/scss/_buttons.scss */
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

/* line 55, node_modules/bootstrap/scss/_buttons.scss */
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-primary {
  color: #fff;
  background-color: #043565;
  border-color: #043565; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-primary:hover {
    color: #fff;
    background-color: #032240;
    border-color: #021b34; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #032240;
    border-color: #021b34;
    box-shadow: 0 0 0 0.2rem rgba(42, 83, 124, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #043565;
    border-color: #043565; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #021b34;
    border-color: #021528; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 83, 124, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-success {
  color: #0C202F;
  background-color: #d9edb5;
  border-color: #d9edb5; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-success:hover {
    color: #0C202F;
    background-color: #c9e696;
    border-color: #c4e38c; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success:focus, .btn-success.focus {
    color: #0C202F;
    background-color: #c9e696;
    border-color: #c4e38c;
    box-shadow: 0 0 0 0.2rem rgba(186, 206, 161, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success.disabled, .btn-success:disabled {
    color: #0C202F;
    background-color: #d9edb5;
    border-color: #d9edb5; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #0C202F;
    background-color: #c4e38c;
    border-color: #bfe182; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 206, 161, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-warning {
  color: #0C202F;
  background-color: #ffc107;
  border-color: #ffc107; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-warning:hover {
    color: #0C202F;
    background-color: #e0a800;
    border-color: #d39e00; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning:focus, .btn-warning.focus {
    color: #0C202F;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(219, 169, 13, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning.disabled, .btn-warning:disabled {
    color: #0C202F;
    background-color: #ffc107;
    border-color: #ffc107; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #0C202F;
    background-color: #d39e00;
    border-color: #c69500; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(219, 169, 13, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-light {
  color: #0C202F;
  background-color: #FBFCFE;
  border-color: #FBFCFE; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-light:hover {
    color: #0C202F;
    background-color: #dce5f6;
    border-color: #d2ddf4; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light:focus, .btn-light.focus {
    color: #0C202F;
    background-color: #dce5f6;
    border-color: #d2ddf4;
    box-shadow: 0 0 0 0.2rem rgba(215, 219, 223, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light.disabled, .btn-light:disabled {
    color: #0C202F;
    background-color: #FBFCFE;
    border-color: #FBFCFE; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #0C202F;
    background-color: #d2ddf4;
    border-color: #c8d6f1; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(215, 219, 223, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-dark {
  color: #fff;
  background-color: #010619;
  border-color: #010619; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-dark:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(39, 43, 60, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #010619;
    border-color: #010619; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 43, 60, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-primary {
  color: #043565;
  border-color: #043565; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #043565;
    border-color: #043565; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 53, 101, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #043565;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #043565;
    border-color: #043565; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(4, 53, 101, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-success {
  color: #d9edb5;
  border-color: #d9edb5; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-success:hover {
    color: #0C202F;
    background-color: #d9edb5;
    border-color: #d9edb5; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 237, 181, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #d9edb5;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #0C202F;
    background-color: #d9edb5;
    border-color: #d9edb5; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 237, 181, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-warning:hover {
    color: #0C202F;
    background-color: #ffc107;
    border-color: #ffc107; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #0C202F;
    background-color: #ffc107;
    border-color: #ffc107; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-light {
  color: #FBFCFE;
  border-color: #FBFCFE; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-light:hover {
    color: #0C202F;
    background-color: #FBFCFE;
    border-color: #FBFCFE; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(251, 252, 254, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FBFCFE;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #0C202F;
    background-color: #FBFCFE;
    border-color: #FBFCFE; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(251, 252, 254, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-dark {
  color: #010619;
  border-color: #010619; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #010619;
    border-color: #010619; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 6, 25, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #010619;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #010619;
    border-color: #010619; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(1, 6, 25, 0.5); }

/* line 83, node_modules/bootstrap/scss/_buttons.scss */
.btn-link {
  font-weight: 400;
  color: #043565;
  text-decoration: none; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-link:hover {
    color: #010e1b;
    text-decoration: none; }
  /* line 93, node_modules/bootstrap/scss/_buttons.scss */
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  /* line 98, node_modules/bootstrap/scss/_buttons.scss */
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

/* line 112, node_modules/bootstrap/scss/_buttons.scss */
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 116, node_modules/bootstrap/scss/_buttons.scss */
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 125, node_modules/bootstrap/scss/_buttons.scss */
.btn-block {
  display: block;
  width: 100%; }
  /* line 130, node_modules/bootstrap/scss/_buttons.scss */
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

/* line 139, node_modules/bootstrap/scss/_buttons.scss */
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

/* line 1, node_modules/bootstrap/scss/_transitions.scss */
.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    /* line 1, node_modules/bootstrap/scss/_transitions.scss */
    .fade {
      transition: none; } }
  /* line 4, node_modules/bootstrap/scss/_transitions.scss */
  .fade:not(.show) {
    opacity: 0; }

/* line 10, node_modules/bootstrap/scss/_transitions.scss */
.collapse:not(.show) {
  display: none; }

/* line 15, node_modules/bootstrap/scss/_transitions.scss */
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    /* line 15, node_modules/bootstrap/scss/_transitions.scss */
    .collapsing {
      transition: none; } }
  /* line 21, node_modules/bootstrap/scss/_transitions.scss */
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      /* line 21, node_modules/bootstrap/scss/_transitions.scss */
      .collapsing.width {
        transition: none; } }

/* line 6, node_modules/bootstrap/scss/_nav.scss */
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

/* line 14, node_modules/bootstrap/scss/_nav.scss */
.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  /* line 24, node_modules/bootstrap/scss/_nav.scss */
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

/* line 35, node_modules/bootstrap/scss/_nav.scss */
.nav-tabs {
  border-bottom: 1px solid #CACAD6; }
  /* line 38, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #FBFCFE #FBFCFE #CACAD6; }
    /* line 50, node_modules/bootstrap/scss/_nav.scss */
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  /* line 57, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #010619;
    background-color: #FBFCFE;
    border-color: #CACAD6 #CACAD6 #FBFCFE; }
  /* line 64, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/* line 78, node_modules/bootstrap/scss/_nav.scss */
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

/* line 84, node_modules/bootstrap/scss/_nav.scss */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #043565; }

/* line 97, node_modules/bootstrap/scss/_nav.scss */
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

/* line 105, node_modules/bootstrap/scss/_nav.scss */
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

/* line 119, node_modules/bootstrap/scss/_nav.scss */
.tab-content > .tab-pane {
  display: none; }

/* line 122, node_modules/bootstrap/scss/_nav.scss */
.tab-content > .active {
  display: block; }

/* line 5, node_modules/bootstrap/scss/_card.scss */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  /* line 17, node_modules/bootstrap/scss/_card.scss */
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  /* line 22, node_modules/bootstrap/scss/_card.scss */
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    /* line 26, node_modules/bootstrap/scss/_card.scss */
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    /* line 31, node_modules/bootstrap/scss/_card.scss */
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  /* line 39, node_modules/bootstrap/scss/_card.scss */
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

/* line 45, node_modules/bootstrap/scss/_card.scss */
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

/* line 56, node_modules/bootstrap/scss/_card.scss */
.card-title {
  margin-bottom: 0.75rem; }

/* line 60, node_modules/bootstrap/scss/_card.scss */
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

/* line 65, node_modules/bootstrap/scss/_card.scss */
.card-text:last-child {
  margin-bottom: 0; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.card-link:hover {
  text-decoration: none; }

/* line 74, node_modules/bootstrap/scss/_card.scss */
.card-link + .card-link {
  margin-left: 1.25rem; }

/* line 83, node_modules/bootstrap/scss/_card.scss */
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 90, node_modules/bootstrap/scss/_card.scss */
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

/* line 95, node_modules/bootstrap/scss/_card.scss */
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 101, node_modules/bootstrap/scss/_card.scss */
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

/* line 111, node_modules/bootstrap/scss/_card.scss */
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

/* line 118, node_modules/bootstrap/scss/_card.scss */
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

/* line 124, node_modules/bootstrap/scss/_card.scss */
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

/* line 134, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

/* line 141, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

/* line 146, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

/* line 155, node_modules/bootstrap/scss/_card.scss */
.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  /* line 154, node_modules/bootstrap/scss/_card.scss */
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    /* line 165, node_modules/bootstrap/scss/_card.scss */
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

/* line 183, node_modules/bootstrap/scss/_card.scss */
.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  /* line 180, node_modules/bootstrap/scss/_card.scss */
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    /* line 192, node_modules/bootstrap/scss/_card.scss */
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      /* line 197, node_modules/bootstrap/scss/_card.scss */
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      /* line 204, node_modules/bootstrap/scss/_card.scss */
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        /* line 207, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        /* line 212, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      /* line 219, node_modules/bootstrap/scss/_card.scss */
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        /* line 222, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        /* line 227, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

/* line 244, node_modules/bootstrap/scss/_card.scss */
.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  /* line 243, node_modules/bootstrap/scss/_card.scss */
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    /* line 254, node_modules/bootstrap/scss/_card.scss */
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

/* line 266, node_modules/bootstrap/scss/_card.scss */
.accordion {
  overflow-anchor: none; }
  /* line 269, node_modules/bootstrap/scss/_card.scss */
  .accordion > .card {
    overflow: hidden; }
    /* line 272, node_modules/bootstrap/scss/_card.scss */
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    /* line 277, node_modules/bootstrap/scss/_card.scss */
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    /* line 281, node_modules/bootstrap/scss/_card.scss */
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

/* line 6, node_modules/bootstrap/scss/_badge.scss */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 6, node_modules/bootstrap/scss/_badge.scss */
    .badge {
      transition: none; } }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  /* line 25, node_modules/bootstrap/scss/_badge.scss */
  .badge:empty {
    display: none; }

/* line 31, node_modules/bootstrap/scss/_badge.scss */
.btn .badge {
  position: relative;
  top: -1px; }

/* line 40, node_modules/bootstrap/scss/_badge.scss */
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-primary {
  color: #fff;
  background-color: #043565; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #021b34; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(4, 53, 101, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-success {
  color: #0C202F;
  background-color: #d9edb5; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-success:hover, a.badge-success:focus {
    color: #0C202F;
    background-color: #c4e38c; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(217, 237, 181, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-warning {
  color: #0C202F;
  background-color: #ffc107; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-warning:hover, a.badge-warning:focus {
    color: #0C202F;
    background-color: #d39e00; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-light {
  color: #0C202F;
  background-color: #FBFCFE; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-light:hover, a.badge-light:focus {
    color: #0C202F;
    background-color: #d2ddf4; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(251, 252, 254, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-dark {
  color: #fff;
  background-color: #010619; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: black; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(1, 6, 25, 0.5); }

/* line 3, node_modules/bootstrap/scss/utilities/_align.scss */
.align-baseline {
  vertical-align: baseline !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_align.scss */
.align-top {
  vertical-align: top !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_align.scss */
.align-middle {
  vertical-align: middle !important; }

/* line 6, node_modules/bootstrap/scss/utilities/_align.scss */
.align-bottom {
  vertical-align: bottom !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_align.scss */
.align-text-bottom {
  vertical-align: text-bottom !important; }

/* line 8, node_modules/bootstrap/scss/utilities/_align.scss */
.align-text-top {
  vertical-align: text-top !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-primary {
  background-color: #043565 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #021b34 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-secondary {
  background-color: #6c757d !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-success {
  background-color: #d9edb5 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #c4e38c !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-info {
  background-color: #17a2b8 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-warning {
  background-color: #ffc107 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-danger {
  background-color: #dc3545 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-light {
  background-color: #FBFCFE !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d2ddf4 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-dark {
  background-color: #010619 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_background.scss */
.bg-white {
  background-color: #fff !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_background.scss */
.bg-transparent {
  background-color: transparent !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_borders.scss */
.border {
  border: 1px solid #CACAD6 !important; }

/* line 8, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-top {
  border-top: 1px solid #CACAD6 !important; }

/* line 9, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-right {
  border-right: 1px solid #CACAD6 !important; }

/* line 10, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-bottom {
  border-bottom: 1px solid #CACAD6 !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-left {
  border-left: 1px solid #CACAD6 !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-0 {
  border: 0 !important; }

/* line 14, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-top-0 {
  border-top: 0 !important; }

/* line 15, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-right-0 {
  border-right: 0 !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-bottom-0 {
  border-bottom: 0 !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-left-0 {
  border-left: 0 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-primary {
  border-color: #043565 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-secondary {
  border-color: #6c757d !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-success {
  border-color: #d9edb5 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-info {
  border-color: #17a2b8 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-warning {
  border-color: #ffc107 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-danger {
  border-color: #dc3545 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-light {
  border-color: #FBFCFE !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-dark {
  border-color: #010619 !important; }

/* line 25, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-white {
  border-color: #fff !important; }

/* line 33, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-sm {
  border-radius: 0.2rem !important; }

/* line 37, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded {
  border-radius: 0.25rem !important; }

/* line 41, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

/* line 46, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

/* line 51, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

/* line 56, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

/* line 61, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-lg {
  border-radius: 0.3rem !important; }

/* line 65, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-circle {
  border-radius: 50% !important; }

/* line 69, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-pill {
  border-radius: 50rem !important; }

/* line 73, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-0 {
  border-radius: 0 !important; }

/* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */
.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-none {
  display: none !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline {
  display: inline !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline-block {
  display: inline-block !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-block {
  display: block !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table {
  display: table !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table-row {
  display: table-row !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table-cell {
  display: table-cell !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-flex {
  display: flex !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xxl-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xxl-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xxl-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xxl-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xxl-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xxl-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xxl-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xxl-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-none {
    display: none !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline {
    display: inline !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline-block {
    display: inline-block !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-block {
    display: block !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table {
    display: table !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table-row {
    display: table-row !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table-cell {
    display: table-cell !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-flex {
    display: flex !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* line 3, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  /* line 10, node_modules/bootstrap/scss/utilities/_embed.scss */
  .embed-responsive::before {
    display: block;
    content: ""; }
  /* line 15, node_modules/bootstrap/scss/utilities/_embed.scss */
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%; }

/* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-row {
  flex-direction: row !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-column {
  flex-direction: column !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-row-reverse {
  flex-direction: row-reverse !important; }

/* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-column-reverse {
  flex-direction: column-reverse !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-wrap {
  flex-wrap: wrap !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-nowrap {
  flex-wrap: nowrap !important; }

/* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

/* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-fill {
  flex: 1 1 auto !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-grow-0 {
  flex-grow: 0 !important; }

/* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-grow-1 {
  flex-grow: 1 !important; }

/* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-shrink-0 {
  flex-shrink: 0 !important; }

/* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-shrink-1 {
  flex-shrink: 1 !important; }

/* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-start {
  justify-content: flex-start !important; }

/* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-end {
  justify-content: flex-end !important; }

/* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-center {
  justify-content: center !important; }

/* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-between {
  justify-content: space-between !important; }

/* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-around {
  justify-content: space-around !important; }

/* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-start {
  align-items: flex-start !important; }

/* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-end {
  align-items: flex-end !important; }

/* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-center {
  align-items: center !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-baseline {
  align-items: baseline !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-stretch {
  align-items: stretch !important; }

/* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-start {
  align-content: flex-start !important; }

/* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-end {
  align-content: flex-end !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-center {
  align-content: center !important; }

/* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-between {
  align-content: space-between !important; }

/* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-around {
  align-content: space-around !important; }

/* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-stretch {
  align-content: stretch !important; }

/* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-auto {
  align-self: auto !important; }

/* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-start {
  align-self: flex-start !important; }

/* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-end {
  align-self: flex-end !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-center {
  align-self: center !important; }

/* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-baseline {
  align-self: baseline !important; }

/* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xxl-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xxl-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xxl-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xxl-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xxl-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xxl-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xxl-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xxl-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xxl-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xxl-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xxl-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xxl-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xxl-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

/* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
.float-left {
  float: left !important; }

/* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
.float-right {
  float: right !important; }

/* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
.float-none {
  float: none !important; }

@media (min-width: 576px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1600px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xxl-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xxl-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xxl-none {
    float: none !important; } }

/* line 4, node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-all {
  user-select: all !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-auto {
  user-select: auto !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-none {
  user-select: none !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */
.overflow-auto {
  overflow: auto !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */
.overflow-hidden {
  overflow: hidden !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-static {
  position: static !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-relative {
  position: relative !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-absolute {
  position: absolute !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-fixed {
  position: fixed !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-sticky {
  position: sticky !important; }

/* line 10, node_modules/bootstrap/scss/utilities/_position.scss */
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

/* line 18, node_modules/bootstrap/scss/utilities/_position.scss */
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  /* line 26, node_modules/bootstrap/scss/utilities/_position.scss */
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

/* line 5, node_modules/bootstrap/scss/utilities/_screenreaders.scss */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/* line 25, node_modules/bootstrap/scss/mixins/_screen-reader.scss */
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

/* line 3, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow, .card-hover-style-shadow:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

/* line 6, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-none {
  box-shadow: none !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-25 {
  width: 25% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-50 {
  width: 50% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-75 {
  width: 75% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-100 {
  width: 100% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-auto {
  width: auto !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-25 {
  height: 25% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-50 {
  height: 50% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-75 {
  height: 75% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-100 {
  height: 100% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-auto {
  height: auto !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_sizing.scss */
.mw-100 {
  max-width: 100% !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_sizing.scss */
.mh-100 {
  max-height: 100% !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_sizing.scss */
.min-vw-100 {
  min-width: 100vw !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_sizing.scss */
.min-vh-100 {
  min-height: 100vh !important; }

/* line 19, node_modules/bootstrap/scss/utilities/_sizing.scss */
.vw-100 {
  width: 100vw !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_sizing.scss */
.vh-100 {
  height: 100vh !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-0 {
  margin: 0 !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-0,
.my-0 {
  margin-top: 0 !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-0,
.mx-0 {
  margin-right: 0 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-0,
.mx-0 {
  margin-left: 0 !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-1 {
  margin: 0.25rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-2 {
  margin: 0.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-3 {
  margin: 1rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-3,
.my-3 {
  margin-top: 1rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-4 {
  margin: 1.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-5 {
  margin: 3rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-5,
.my-5 {
  margin-top: 3rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-6 {
  margin: 5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-6,
.my-6 {
  margin-top: 5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-6,
.mx-6 {
  margin-right: 5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-6,
.my-6 {
  margin-bottom: 5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-6,
.mx-6 {
  margin-left: 5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-7 {
  margin: 7rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-7,
.my-7 {
  margin-top: 7rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-7,
.mx-7 {
  margin-right: 7rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-7,
.my-7 {
  margin-bottom: 7rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-7,
.mx-7 {
  margin-left: 7rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-8 {
  margin: 9rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-8,
.my-8 {
  margin-top: 9rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-8,
.mx-8 {
  margin-right: 9rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-8,
.my-8 {
  margin-bottom: 9rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-8,
.mx-8 {
  margin-left: 9rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-0 {
  padding: 0 !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-0,
.py-0 {
  padding-top: 0 !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-0,
.px-0 {
  padding-right: 0 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-0,
.px-0 {
  padding-left: 0 !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-1 {
  padding: 0.25rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-2 {
  padding: 0.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-3 {
  padding: 1rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-3,
.py-3 {
  padding-top: 1rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-3,
.px-3 {
  padding-right: 1rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-3,
.px-3 {
  padding-left: 1rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-4 {
  padding: 1.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-5 {
  padding: 3rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-5,
.py-5 {
  padding-top: 3rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-5,
.px-5 {
  padding-right: 3rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-5,
.px-5 {
  padding-left: 3rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-6 {
  padding: 5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-6,
.py-6 {
  padding-top: 5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-6,
.px-6 {
  padding-right: 5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-6,
.py-6 {
  padding-bottom: 5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-6,
.px-6 {
  padding-left: 5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-7 {
  padding: 7rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-7,
.py-7 {
  padding-top: 7rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-7,
.px-7 {
  padding-right: 7rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-7,
.py-7 {
  padding-bottom: 7rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-7,
.px-7 {
  padding-left: 7rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-8 {
  padding: 9rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-8,
.py-8 {
  padding-top: 9rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-8,
.px-8 {
  padding-right: 9rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-8,
.py-8 {
  padding-bottom: 9rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-8,
.px-8 {
  padding-left: 9rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n1 {
  margin: -0.25rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n2 {
  margin: -0.5rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n3 {
  margin: -1rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n4 {
  margin: -1.5rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n5 {
  margin: -3rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n6 {
  margin: -5rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n6,
.my-n6 {
  margin-top: -5rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n6,
.mx-n6 {
  margin-right: -5rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n6,
.my-n6 {
  margin-bottom: -5rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n6,
.mx-n6 {
  margin-left: -5rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n7 {
  margin: -7rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n7,
.my-n7 {
  margin-top: -7rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n7,
.mx-n7 {
  margin-right: -7rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n7,
.mx-n7 {
  margin-left: -7rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n8 {
  margin: -9rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n8,
.my-n8 {
  margin-top: -9rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n8,
.mx-n8 {
  margin-right: -9rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n8,
.my-n8 {
  margin-bottom: -9rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n8,
.mx-n8 {
  margin-left: -9rem !important; }

/* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-auto {
  margin: auto !important; }

/* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-auto,
.my-auto {
  margin-top: auto !important; }

/* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-auto,
.mx-auto {
  margin-right: auto !important; }

/* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

/* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-6 {
    margin: 5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-7 {
    margin: 7rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-8 {
    margin: 9rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 9rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 9rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 9rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 9rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-6 {
    padding: 5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-7 {
    padding: 7rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 7rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 7rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 7rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-8 {
    padding: 9rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 9rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 9rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 9rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 9rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n6 {
    margin: -5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n7 {
    margin: -7rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -7rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -7rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -7rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -7rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n8 {
    margin: -9rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -9rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -9rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -9rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -9rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-6 {
    margin: 5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-6,
  .my-md-6 {
    margin-top: 5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-6,
  .mx-md-6 {
    margin-right: 5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-6,
  .mx-md-6 {
    margin-left: 5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-7 {
    margin: 7rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-7,
  .my-md-7 {
    margin-top: 7rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-7,
  .mx-md-7 {
    margin-right: 7rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-8 {
    margin: 9rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-8,
  .my-md-8 {
    margin-top: 9rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-8,
  .mx-md-8 {
    margin-right: 9rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 9rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-8,
  .mx-md-8 {
    margin-left: 9rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-6 {
    padding: 5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-7 {
    padding: 7rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-7,
  .py-md-7 {
    padding-top: 7rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-7,
  .px-md-7 {
    padding-right: 7rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-7,
  .px-md-7 {
    padding-left: 7rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-8 {
    padding: 9rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-8,
  .py-md-8 {
    padding-top: 9rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-8,
  .px-md-8 {
    padding-right: 9rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 9rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-8,
  .px-md-8 {
    padding-left: 9rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n6 {
    margin: -5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n7 {
    margin: -7rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -7rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -7rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -7rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -7rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n8 {
    margin: -9rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -9rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -9rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -9rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -9rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-6 {
    margin: 5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-7 {
    margin: 7rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-8 {
    margin: 9rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 9rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 9rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 9rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 9rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-6 {
    padding: 5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-7 {
    padding: 7rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 7rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 7rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 7rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-8 {
    padding: 9rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 9rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 9rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 9rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 9rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n6 {
    margin: -5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n7 {
    margin: -7rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -7rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -7rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -7rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -7rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n8 {
    margin: -9rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -9rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -9rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -9rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -9rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-6 {
    margin: 5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-7 {
    margin: 7rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-8 {
    margin: 9rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 9rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 9rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 9rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 9rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-6 {
    padding: 5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-7 {
    padding: 7rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 7rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 7rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 7rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-8 {
    padding: 9rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 9rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 9rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 9rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 9rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n6 {
    margin: -5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n7 {
    margin: -7rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -7rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -7rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -7rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -7rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n8 {
    margin: -9rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -9rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -9rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -9rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -9rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-6 {
    margin: 5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-7 {
    margin: 7rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 7rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 7rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 7rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 7rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-8 {
    margin: 9rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-8,
  .my-xxl-8 {
    margin-top: 9rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-8,
  .mx-xxl-8 {
    margin-right: 9rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-8,
  .my-xxl-8 {
    margin-bottom: 9rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-8,
  .mx-xxl-8 {
    margin-left: 9rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xxl-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xxl-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xxl-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xxl-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xxl-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xxl-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xxl-6 {
    padding: 5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xxl-7 {
    padding: 7rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 7rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 7rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 7rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 7rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xxl-8 {
    padding: 9rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xxl-8,
  .py-xxl-8 {
    padding-top: 9rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xxl-8,
  .px-xxl-8 {
    padding-right: 9rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xxl-8,
  .py-xxl-8 {
    padding-bottom: 9rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xxl-8,
  .px-xxl-8 {
    padding-left: 9rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-n6 {
    margin: -5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-n7 {
    margin: -7rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -7rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -7rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -7rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -7rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-n8 {
    margin: -9rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-n8,
  .my-xxl-n8 {
    margin-top: -9rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-n8,
  .mx-xxl-n8 {
    margin-right: -9rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-n8,
  .my-xxl-n8 {
    margin-bottom: -9rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-n8,
  .mx-xxl-n8 {
    margin-left: -9rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xxl-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

/* line 6, node_modules/bootstrap/scss/utilities/_stretched-link.scss */
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

/* line 7, node_modules/bootstrap/scss/utilities/_text.scss */
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_text.scss */
.text-justify {
  text-align: justify !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_text.scss */
.text-wrap {
  white-space: normal !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_text.scss */
.text-nowrap {
  white-space: nowrap !important; }

/* line 14, node_modules/bootstrap/scss/utilities/_text.scss */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
.text-left {
  text-align: left !important; }

/* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
.text-right {
  text-align: right !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xxl-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xxl-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xxl-center {
    text-align: center !important; } }

/* line 30, node_modules/bootstrap/scss/utilities/_text.scss */
.text-lowercase {
  text-transform: lowercase !important; }

/* line 31, node_modules/bootstrap/scss/utilities/_text.scss */
.text-uppercase, .h6, h6 {
  text-transform: uppercase !important; }

/* line 32, node_modules/bootstrap/scss/utilities/_text.scss */
.text-capitalize {
  text-transform: capitalize !important; }

/* line 36, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-light {
  font-weight: 300 !important; }

/* line 37, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-lighter {
  font-weight: lighter !important; }

/* line 38, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-normal {
  font-weight: 400 !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-bold, .h6, h6 {
  font-weight: 600 !important; }

/* line 40, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-bolder {
  font-weight: 600 !important; }

/* line 41, node_modules/bootstrap/scss/utilities/_text.scss */
.font-italic {
  font-style: italic !important; }

/* line 45, node_modules/bootstrap/scss/utilities/_text.scss */
.text-white {
  color: #fff !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-primary {
  color: #043565 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-primary:hover, a.text-primary:focus {
  color: #010e1b !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-secondary {
  color: #6c757d !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-success {
  color: #d9edb5 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-success:hover, a.text-success:focus {
  color: #b9de77 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-info {
  color: #17a2b8 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-warning {
  color: #ffc107 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-danger {
  color: #dc3545 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-light {
  color: #FBFCFE !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-light:hover, a.text-light:focus {
  color: #beceef !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-dark {
  color: #010619 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

/* line 51, node_modules/bootstrap/scss/utilities/_text.scss */
.text-body {
  color: #0C202F !important; }

/* line 52, node_modules/bootstrap/scss/utilities/_text.scss */
.text-muted {
  color: #6c757d !important; }

/* line 54, node_modules/bootstrap/scss/utilities/_text.scss */
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

/* line 55, node_modules/bootstrap/scss/utilities/_text.scss */
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

/* line 59, node_modules/bootstrap/scss/utilities/_text.scss */
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

/* line 63, node_modules/bootstrap/scss/utilities/_text.scss */
.text-decoration-none {
  text-decoration: none !important; }

/* line 65, node_modules/bootstrap/scss/utilities/_text.scss */
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

/* line 72, node_modules/bootstrap/scss/utilities/_text.scss */
.text-reset {
  color: inherit !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_visibility.scss */
.visible {
  visibility: visible !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_visibility.scss */
.invisible {
  visibility: hidden !important; }

@media print {
  /* line 13, node_modules/bootstrap/scss/_print.scss */
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  /* line 24, node_modules/bootstrap/scss/_print.scss */
  a:not(.btn) {
    text-decoration: underline; }
  /* line 34, node_modules/bootstrap/scss/_print.scss */
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /* line 49, node_modules/bootstrap/scss/_print.scss */
  pre {
    white-space: pre-wrap !important; }
  /* line 52, node_modules/bootstrap/scss/_print.scss */
  pre,
  blockquote {
    border: 1px solid #8688A0;
    page-break-inside: avoid; }
  /* line 58, node_modules/bootstrap/scss/_print.scss */
  tr,
  img {
    page-break-inside: avoid; }
  /* line 63, node_modules/bootstrap/scss/_print.scss */
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  /* line 70, node_modules/bootstrap/scss/_print.scss */
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  /* line 83, node_modules/bootstrap/scss/_print.scss */
  body {
    min-width: 992px !important; }
  /* line 86, node_modules/bootstrap/scss/_print.scss */
  .container {
    min-width: 992px !important; }
  /* line 91, node_modules/bootstrap/scss/_print.scss */
  .navbar {
    display: none; }
  /* line 94, node_modules/bootstrap/scss/_print.scss */
  .badge {
    border: 1px solid #000; }
  /* line 98, node_modules/bootstrap/scss/_print.scss */
  .table {
    border-collapse: collapse !important; }
    /* line 101, node_modules/bootstrap/scss/_print.scss */
    .table td,
    .table th {
      background-color: #fff !important; }
  /* line 108, node_modules/bootstrap/scss/_print.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #CACAD6 !important; }
  /* line 114, node_modules/bootstrap/scss/_print.scss */
  .table-dark {
    color: inherit; }
    /* line 117, node_modules/bootstrap/scss/_print.scss */
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #CACAD6; }
  /* line 125, node_modules/bootstrap/scss/_print.scss */
  .table .thead-dark th {
    color: inherit;
    border-color: #CACAD6; } }

/* sample file */
/* sample file */
/* line 56, src/assets/scss/app.scss */
.link-default {
  position: relative;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(4, 53, 101, 0.2); }
  /* line 61, src/assets/scss/app.scss */
  .link-default:visited:after {
    background-color: transparent; }
  /* line 65, src/assets/scss/app.scss */
  .link-default:after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #043565;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transform: translateY(1px);
    -moz-transform: translateY(1px);
    transform: translateY(1px); }
  /* line 81, src/assets/scss/app.scss */
  .link-default:hover::after, .link-default:focus::after {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px); }
  /* line 88, src/assets/scss/app.scss */
  .link-default:active {
    top: 2px; }
    /* line 90, src/assets/scss/app.scss */
    .link-default:active:after {
      background-color: #043565; }

/* line 96, src/assets/scss/app.scss */
.card-hover-style-shadow {
  transition: box-shadow 0.3s; }

/* line 103, src/assets/scss/app.scss */
.font-family-secondary {
  font-family: "Caveat", cursive; }

/* line 107, src/assets/scss/app.scss */
abbr[title] {
  text-decoration: none;
  border-bottom: 1px dotted #043565; }
  /* line 110, src/assets/scss/app.scss */
  abbr[title]:after {
    content: " (" attr(title) ")"; }
    @media (min-width: 576px) {
      /* line 110, src/assets/scss/app.scss */
      abbr[title]:after {
        content: ""; } }

/* line 119, src/assets/scss/app.scss */
.list-style-default {
  list-style: none;
  padding-left: 0; }
  /* line 122, src/assets/scss/app.scss */
  .list-style-default li:before {
    content: "\25AB";
    position: absolute;
    left: -0.25rem;
    color: #8688A0; }

/* line 130, src/assets/scss/app.scss */
.card-img-height-responsive {
  height: 50vw;
  object-fit: cover;
  object-position: top; }
  @media (min-width: 768px) {
    /* line 130, src/assets/scss/app.scss */
    .card-img-height-responsive {
      height: 15vw; } }
  @media (min-width: 1600px) {
    /* line 130, src/assets/scss/app.scss */
    .card-img-height-responsive {
      height: 10vw; } }

/* line 142, src/assets/scss/app.scss */
html {
  scroll-behavior: smooth; }

/* line 146, src/assets/scss/app.scss */
.letter-spacing-loose, .h6, h6 {
  letter-spacing: 0.1em; }

/* line 150, src/assets/scss/app.scss */
.border-1 {
  border-width: 0.5rem; }

/* line 153, src/assets/scss/app.scss */
.border-r-lg {
  border-right: 8px solid; }

/* line 156, src/assets/scss/app.scss */
.border-b-lg {
  border-bottom: 8px solid; }

/* line 160, src/assets/scss/app.scss */
.bg-img-loading {
  background: url(../img/img-loading.gif) center center no-repeat; }

/* line 164, src/assets/scss/app.scss */
.bg-pattern-dot {
  background-color: #FBFCFE;
  background-image: radial-gradient(rgba(4, 53, 101, 0.16) 1px, #FBFCFE 1px);
  background-size: 16px 16px; }
