//Custom settings --
@import "settings";

//Bootstrap styles 
// @import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
// @import "node_modules/bootstrap/scss/tables";
// @import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
// @import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/button-group";
// @import "node_modules/bootstrap/scss/input-group";
// @import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
// @import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/jumbotron";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/close";
// @import "node_modules/bootstrap/scss/toasts";
// @import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";

//Custom mixins
@import "mixins/mixins";

//Component specific styles 
@import "components/callout";

.h6, h6 {
  @extend .letter-spacing-loose;
  @extend .text-uppercase;
  @extend .font-weight-bold;
}

.link-default {
  position:relative;
  padding-bottom:8px;
  border-bottom: 1px solid transparentize($blue, 0.8);
  &:visited {
    &:after {
      background-color: transparent;
    }
  }
  &:after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background: $blue;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  transform: translateY(1px);
  }
  &:hover::after,
  &:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
  }
  &:active {
    top: 2px;
    &:after {
      background-color: $blue;
    }
  }
}

.card-hover-style-shadow {
  &:hover {
    @extend .shadow;
  }
  transition: box-shadow 0.3s;
}

.font-family-secondary {
  font-family: $font-family-secondary;
}

abbr[title] {
  text-decoration: none;
  border-bottom: 1px dotted $blue;
  &:after {
    content: " (" attr(title) ")";
      @include media-breakpoint-up(sm) {
        content: "";
      }
  }
  
}

.list-style-default {
  list-style: none;
  padding-left: 0;
  li:before {
    content: "\25AB";
    position: absolute;
    left: -0.25rem;
    color: $gray-500;
  }
}

.card-img-height-responsive {
  height: 50vw;
  object-fit: cover;
  object-position: top;
  @include media-breakpoint-up(md) {
      height: 15vw;
  }
  @include media-breakpoint-up(xxl) {
      height: 10vw;
  }
}

html {
  scroll-behavior: smooth;
}

.letter-spacing-loose {
  letter-spacing: 0.1em;
}

.border-1 {
  border-width: 0.5rem;
}
.border-r-lg {
  border-right: 8px solid;
}
.border-b-lg {
  border-bottom: 8px solid;
}

.bg-img-loading {
  background: url(../img/img-loading.gif) center center no-repeat;
}

.bg-pattern-dot {
  background-color: $gray-200;
  background-image: radial-gradient(adjust-color($blue, $alpha:-0.84) 1px, $gray-200 1px);
  background-size: 16px 16px;
}
